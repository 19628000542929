import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Login,
  ForgotPassword,
  Dashboard,
  Home,
} from "./";
import { Header } from "../components/Header";
import { PrivateRoute } from "../commons/private-route";
import { Content } from "antd/lib/layout/layout";
import { Spin } from "antd";
import { useContext, useEffect } from "react";
import { AuthContext } from "../components/Providers/Auth";

export function Main() {
  const { getSession, loading } = useContext(AuthContext);

  useEffect(() => {
    getSession();
  }, []);

  if (loading) {
    return <div style={{marginTop: '4rem'}}><Spin /></div>;
  }

  return (
    <BrowserRouter>
      <Header />
      <Content className="main-container">
        <Routes>
          <Route path="/*" element={<Home user={undefined} />}></Route>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/dashboard" element={<PrivateRoute />}>
            <Route index element={<Dashboard />} />
          </Route>
        </Routes>
      </Content>
    </BrowserRouter>
  );
}
