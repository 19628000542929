import { Badge, Card, Typography, Image } from "antd";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { useMemo } from "react";
import { statusConverter } from "../commons/functions/matches";

interface Props {
  id: string;
  homeTeam: string;
  awayTeam: string;
  date: string;
  status: string;
  homeTeamGoals: number;
  awayTeamGoals: number;
  competitionStage: string;
  teams: any[];
  showGroup: boolean;
}

function Match({
  homeTeam,
  awayTeam,
  date,
  status,
  homeTeamGoals,
  awayTeamGoals,
  teams,
  competitionStage,
  showGroup,
}: Props) {
  const locales = { es };
  const getFormattedDateFromString = (date: string) => {
    let formattedDate = format(new Date(date), "d MMMM HH:mm", {
      locale: locales["es"],
    }).split(" ");
    return (
      formattedDate[0] +
      " de " +
      formattedDate[1].charAt(0).toUpperCase() +
      formattedDate[1].slice(1) +
      " " +
      formattedDate[2]
    );
  };

  const homeTeamComplete = useMemo(
    () => teams.find((team) => team.code === homeTeam),
    [teams, homeTeam]
  );
  const awayTeamComplete = useMemo(
    () => teams.find((team) => team.code === awayTeam),
    [teams, awayTeam]
  );

  const isAboutToStart = (date) => {
    const res = new Date(date).getTime() - new Date().getTime();
    return res <= 15 * 60 * 1000 && res >= 0;
  }

  return (
    <>
      <Card className="match-card" size="default">
        <div className="match-card__team">
          <Image
            src={`assets/round-flags/${homeTeam}.svg`}
            width={40}
            height={40}
            preview={false}
          />
          <Typography.Text strong>
            {homeTeamComplete?.name}
          </Typography.Text>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {showGroup && (
            <Typography.Text strong style={{ fontSize: "14px", color: "#888" }}>
              {competitionStage}
            </Typography.Text>
          )}
          <Typography.Text strong>
            {status === "SCHEDULED" ? (
              isAboutToStart(date) ? (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  {getFormattedDateFromString(date as unknown as string)}
                  <Badge count={'Por comenzar'} style={{ backgroundColor: 'purple' }}></Badge>
                </div>
              ) : (
                <div style={{textAlign: 'center'}}>
                  {getFormattedDateFromString(date as unknown as string)}
                </div>
              )
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography.Text style={{ fontSize: "20px" }}>
                  {homeTeamGoals} - {awayTeamGoals}
                </Typography.Text>
                <Badge
                  count={statusConverter(status)?.text}
                  style={{
                    backgroundColor: `${statusConverter(status)?.color}`,
                  }}
                />
              </div>
            )}
          </Typography.Text>
        </div>
        <div className="match-card__team">
          <Image
            src={`assets/round-flags/${awayTeam}.svg`}
            width={40}
            height={40}
            preview={false}
          />
          <Typography.Text strong>
            {awayTeamComplete?.name}
          </Typography.Text>
        </div>
      </Card>
    </>
  );
}

export default Match;
