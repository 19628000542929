import { Badge, Button, Dropdown, Image, Layout, Menu, Modal } from "antd";
import type { MenuProps } from "antd";
import { useContext, useState } from "react";
import { AuthContext } from "./Providers/Auth";
import { DownOutlined } from "@ant-design/icons";
import { useUserPoints } from "../hooks/users";
const { Header: AntHeader } = Layout;

export function Header() {
  const { authed, logout, userSession } = useContext(AuthContext);

  const items: MenuProps["items"] = [
    {
      key: "dashboard",
      label: <a href="/dashboard">Cambiar contraseña</a>,
    },
    {
      type: "divider",
    },
    {
      key: "logout",
      label: <a onClick={logout}>Cerrar Sesión</a>,
    },
  ];

  const userMenuProps = {
    items,
  };

  const unauthedItems = [
    {
      key: "login",
      label: <a href="/login">Ingresar</a>,
    }
  ];

  const authedItems = [
    {
      key: "results",
      label: <a href="/">Resultados</a>,
    },
    {
      key: "user",
      label: (
        <Dropdown overlay={<Menu {...userMenuProps}></Menu>}>
          <Button>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {userSession?.preferred_username}
              <DownOutlined />
            </div>
          </Button>
        </Dropdown>
      ),
    }
  ];

  return (
    <>
      <AntHeader className="header-nav">
        <div>
          <a href="/" style={{ display: "flex", flexDirection: "row", width: '300px' }}>
            <Image src={"/assets/coperos.png"} preview={false} width={22} />
            <h1 style={{ marginBottom: "0", marginTop: "0", marginLeft: "12px" }}>
              Coperos Admin
            </h1>
          </a>
        </div>
        <Menu
          rootClassName="header-nav__menu"
          mode="horizontal"
          items={authed ? authedItems : unauthedItems}
        ></Menu>
      </AntHeader>
    </>
  );
}
