import axios from "axios";
import { Tournament, Match, Team, Prediction } from "@coperos/dtos";
import UserPool from "../UserPool";
import { Config } from "../config";

const getAccessToken = async () => {
  return new Promise((resolve, reject) => {
    const user = UserPool.getCurrentUser();

    if (user) {
      user.getSession((err, session) => {
        if (err) {
          reject(err);
        } else if (!session.isValid()) {
          reject("Session is invalid");
        } else {
          resolve(session.getIdToken().getJwtToken());
        }
      });
    } else {
      reject("No user");
    }
  });
};

const hostname = Config.API_BASE_URL;

export const getTournamentsByName = async (
  name: string
): Promise<Tournament[]> => {
  if (!name) {
    return [];
  }
  const { data } = await axios.get(
    `${hostname}/competitions/${Config.CURRENT_COMPETITION}/tournaments?name=${name}`
  );
  return data;
};

export const getTournament = async (
  tournamentId: string
): Promise<Tournament | undefined> => {
  const { data } = await axios.get(
    `${hostname}/competitions/${Config.CURRENT_COMPETITION}/tournaments/${tournamentId}`
  );
  return data;
};

export const acceptUser = async (tournamentId, userId) => {
  await axios.put(
    `${hostname}/competitions/${Config.CURRENT_COMPETITION}/tournaments/${tournamentId}/inscriptions/${userId}`,
    { status: "ACCEPTED" },
    {
      headers: {
        Authorization: "Bearer " + (await getAccessToken()),
      },
    }
  );
};

export const deleteInscription = async (
  tournamentId,
  userId
): Promise<void> => {
  await axios.delete(
    `${hostname}/competitions/${Config.CURRENT_COMPETITION}/tournaments/${tournamentId}/inscriptions/${userId}`,
    {
      headers: {
        Authorization: "Bearer " + (await getAccessToken()),
      },
    }
  );
};

export const postInscription = async (tournamentId) => {
  await axios.post(
    `${hostname}/competitions/${Config.CURRENT_COMPETITION}/tournaments/${tournamentId}/inscriptions`,
    undefined,
    {
      headers: {
        Authorization: "Bearer " + (await getAccessToken()),
      },
    }
  );
};

export const getTournamentsInscribedByUser = async (
  userId: string
): Promise<Tournament[] | undefined> => {
  const { data } = await axios.get(
    `${hostname}/users/${userId}/competitions/${Config.CURRENT_COMPETITION}/tournaments/inscribed`,
    {
      headers: {
        Authorization: "Bearer " + (await getAccessToken()),
      },
    }
  );
  return data;
};
export const createTournament = async (tournament: Tournament) => {
  const { data } = await axios.post(
    `${hostname}/competitions/${Config.CURRENT_COMPETITION}/tournaments`,
    tournament,
    {
      headers: {
        Authorization: "Bearer " + (await getAccessToken()),
      },
    }
  );
  return data;
};

export const updateTournament = async (tournament, tournamentId) => {
  await axios.put(
    `${hostname}/competitions/${Config.CURRENT_COMPETITION}/tournaments/${tournamentId}`,
    tournament,
    {
      headers: {
        Authorization: "Bearer " + (await getAccessToken()),
      },
    }
  );
};

export const getAllTeams = async () => {
  const { data } = await axios.get(
    `${hostname}/competitions/${Config.CURRENT_COMPETITION}/teams`
  );
  return data;
};

export const getAllMatches = async () => {
  const { data } = await axios.get(
    `${hostname}/competitions/${Config.CURRENT_COMPETITION}/matches`
  );
  return data;
};

export const getUserCreatedTournaments = async (userId) => {
  const { data } = await axios.get(
    `${hostname}/users/${userId}/competitions/${Config.CURRENT_COMPETITION}/tournaments/created`,
    {
      headers: {
        Authorization: "Bearer " + (await getAccessToken()),
      },
    }
  );
  return data;
};

export const getUserInscribedTournaments = async (userId) => {
  const { data } = await axios.get(
    `${hostname}/users/${userId}/competitions/${Config.CURRENT_COMPETITION}/tournaments/inscribed`,
    {
      headers: {
        Authorization: "Bearer " + (await getAccessToken()),
      },
    }
  );
  return data;
};

export const getMatches = async (): Promise<Match[] | undefined> => {
  const { data } = await axios.get(
    `${hostname}/competitions/${Config.CURRENT_COMPETITION}/matches`,
    {
      headers: {
        Authorization: "Bearer " + (await getAccessToken()),
      },
    }
  );
  return data;
};

export const getTeams = async (): Promise<Team[] | undefined> => {
  const { data } = await axios.get(
    `${hostname}/competitions/${Config.CURRENT_COMPETITION}/teams`,
    {
      headers: {
        Authorization: "Bearer " + (await getAccessToken()),
      },
    }
  );
  return data;
};

export const createPrediction = async (
  userId: string,
  prediction: Prediction
) => {
  await axios.post(
    `${hostname}/users/${userId}/competitions/${Config.CURRENT_COMPETITION}/predictions`,
    prediction,
    {
      headers: {
        Authorization: "Bearer " + (await getAccessToken()),
      },
    }
  );
};

export const updateMatch = async (
  match: Match
) => {
  await axios.put(
    `${hostname}/competitions/${Config.CURRENT_COMPETITION}/matches/${match.id}`,
    match,
    {
      headers: {
        Authorization: "Bearer " + (await getAccessToken()),
      },
    }
  );
};

export const getUserPredictions = async (
  userId: string
): Promise<Prediction[] | undefined> => {
  const { data } = await axios.get(
    `${hostname}/users/${userId}/competitions/${Config.CURRENT_COMPETITION}/predictions`,
    {
      headers: {
        Authorization: "Bearer " + (await getAccessToken()),
      },
    }
  );
  return data;
};

export const getUserPoints = async (
  userId: string
): Promise<number | undefined> => {
  const { data } = await axios.get(
    `${hostname}/users/${userId}/competitions/${Config.CURRENT_COMPETITION}/points`,
    {
      headers: {
        Authorization: "Bearer " + (await getAccessToken()),
      },
    }
  );
  return data;
}
