import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createPrediction,
  getAllMatches,
  getAllTeams,
  getUserPredictions,
  updateMatch,
} from "../api/api";

export function useMatches(props = {}) {
  return useQuery(["MATCHES"], getAllMatches, props);
}

export function useTeams(props = {}) {
  return useQuery(["TEAMS"], getAllTeams, props);
}

export function useSaveUserPrediction() {
  return useMutation(
    ({ userId, prediction }: { userId: string; prediction: any }) =>
      createPrediction(userId, prediction)
  );
}

export function useSaveMatch() {
  return useMutation(
    ({match }: { match: any }) =>
      updateMatch(match)
  );
}

export function useUserPredictions(userId: string, props = {}) {
  return useQuery(
    ["USER_PREDICTIONS"],
    () => getUserPredictions(userId),
    props
  );
}
