import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Matches from "../../components/Matches";
import { AuthContext } from "../../components/Providers/Auth";

interface Props {
  user: any;
}

export function Home(props: Props) {
  const { authed, userSession, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!authed) {
      navigate("/login");
    }else {
      const groups = authed ? userSession.accessToken.payload["cognito:groups"] : [];
      if(!groups || !groups.includes('admin')){
        logout();
        navigate("/login");
      }
    }
    
  }, [authed]);
  
  return (
    <>
      <Matches />
    </>
  );
}
