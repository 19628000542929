import { Match, Team } from "@coperos/dtos";
import { Card, Image, Select, Space, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import {
  getFormattedDateFromString,
} from "../commons/functions";
import useDebounce from "../commons/functions/useDebounce";
import { useSaveMatch } from "../hooks/matches";
import Counter from "./Counter";

export function MatchResult({
  match,
  teams,
  showGroup,
}: {
  match: Match;
  teams: Team[];
  userId: string;
  showGroup: boolean;
}) {
  const homeTeam = useMemo(
    () => teams.find((team) => team.code === match.homeTeam),
    [teams, match]
  );
  const awayTeam = useMemo(
    () => teams.find((team) => team.code === match.awayTeam),
    [teams, match]
  );

  const { mutate } = useSaveMatch();

  const [matchState, setMatchState] = useState(match);

  const debouncedMatchState = useDebounce(matchState, 1000);

  useEffect(() => {
    if (
      debouncedMatchState.status !== match?.status || 
      debouncedMatchState.homeTeamGoals !== match?.homeTeamGoals ||
      debouncedMatchState.awayTeamGoals !== match?.awayTeamGoals
    ) {
      mutate({
        match: {
          id: match.id,
          homeTeamGoals: debouncedMatchState.homeTeamGoals,
          awayTeamGoals: debouncedMatchState.awayTeamGoals,
          status: debouncedMatchState.status
        },
      });
    }
  }, [debouncedMatchState]);

  return (
    <>
      <Card className="match-card prediction" size="default">
        <div className="match-card__team">
          <Image
            src={`/assets/round-flags/${homeTeam.code}.svg`}
            width={60}
            height={60}
            preview={false}
          />
          <Typography.Text strong>{homeTeam?.name}</Typography.Text>
        </div>
        <div className="match-card__details">
          {showGroup && (
            <Typography.Text strong style={{ fontSize: "14px", color: "#888" }}>
              {match.competitionStage}
            </Typography.Text>
          )}
          <Space direction="vertical" style={{ display: 'flex', justifyContent: 'center', alignItems:'center' }}>
            <Typography.Text strong>
              <div style={{ textAlign: 'center' }}>
                {getFormattedDateFromString(match.date as unknown as string)}
              </div>
            </Typography.Text>
            <Counter
              homeTeamGoals={matchState.homeTeamGoals}
              awayTeamGoals={matchState.awayTeamGoals}
              setHomeTeamGoals={(value: any) =>
                setMatchState((prevState) => ({
                  ...prevState,
                  homeTeamGoals: value,
                }))
              }
              setAwayTeamGoals={(value: any) =>
                setMatchState((prevState) => ({
                  ...prevState,
                  awayTeamGoals: value,
                }))
              }
            />
            <Select
              value={matchState.status}
              onChange={(value) =>
                setMatchState((prevState) => ({
                  ...prevState,
                  status: value
                }))}
              options={[
                {
                  value: 'SCHEDULED',
                  label: 'Programado',
                },
                {
                  value: 'INPROGRESS',
                  label: 'En Progeso',
                },
                {
                  value: 'INTERVAL',
                  label: 'Entretiempo',
                },
                {
                  value: 'FINISHED',
                  label: 'Finalizado',
                },
              ]}
            />
          </Space>
        </div>
        <div className="match-card__team">
          <Image
            src={`/assets/round-flags/${awayTeam.code}.svg`}
            width={60}
            height={60}
            preview={false}
          />
          <Typography.Text strong>{awayTeam?.name}</Typography.Text>
        </div>
      </Card>
    </>
  );
}
